import React, { Component } from "react"
import Slider from "react-slick"
import * as Styles from "../pages/index.module.scss"

/* ==================================================
  画像の読み込み
================================================== */
import work01 from "../images/p-work--gunze.png"
import work02 from "../images/p-work--bellemaison.png"
import work03 from "../images/p-work--daimarumatsuzakaya.png"

export default class WorkSlider extends Component {
  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      initialSlide: 1,
      centerMode: true,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
    }
    return (
      <div>
        <Slider {...settings}>
          <div className={Styles.workImg}>
            <img
              data-src={work01}
              className="lazyload"
              alt="グンゼオンラインストア様"
            />
            <div className={Styles.workCapture}>
              <p
                className={`${Styles.workCaptureTitle} ${Styles.txtWrapcolorMain}`}
              >
                グンゼオンラインストア様
              </p>
              <div className={Styles.workCaptureText}>
                サイト改修/運用/LP制作
              </div>
            </div>
          </div>

          <div className={Styles.workImg}>
            <img
              data-src={work02}
              className="lazyload"
              alt="ベルベゾンネット様"
            />
            <div className={Styles.workCapture}>
              <div className={Styles.workCaptureTitle}>ベルネゾンネット様</div>
              <div className={Styles.workCaptureText}>
                サイト改修(フロントエンド)/運用/LP制作
              </div>
            </div>
          </div>

          <div className={Styles.workImg}>
            <img
              data-src={work03}
              className="lazyload"
              alt="大丸松坂屋百貨店様"
            />
            <div className={Styles.workCapture}>
              <div className={Styles.workCaptureTitle}>大丸松坂屋百貨店様</div>
              <div className={Styles.workCaptureText}>サイト運用/LP制作</div>
            </div>
          </div>
        </Slider>
      </div>
    )
  }
}
