import React, { useRef, useState, useLayoutEffect, useEffect } from "react"
import { Layout } from "../../components/layout"
import SEO from "../../components/seo"
import * as Styles from "../lp/lp01.module.scss"

/* ==================================================
  画像の読み込み
================================================== */

//メインビジュアル
import main from "../../images/p-main.svg"

// support(サポート内容)
import support01 from "../../images/p-support--01.svg"
import support02 from "../../images/p-support--02.svg"
import support03 from "../../images/p-support--03.svg"
import support04 from "../../images/p-support--04.svg"

// select（TAMが選ばれる理由）
import select01 from "../../images/p-select--01.svg"
import select02 from "../../images/p-select--02.svg"
import select03 from "../../images/p-select--03.svg"
import select04 from "../../images/p-select--04.svg"
import select05 from "../../images/p-select--05.svg"
import select06 from "../../images/p-select--06.svg"
import select07 from "../../images/p-select--07.svg"
import select08 from "../../images/p-select--08.svg"

// reason（成果が上がる３つの根拠）
import reason01 from "../../images/p-reason--01.png"
import reason02 from "../../images/p-reason--02.png"
import reason03 from "../../images/p-reason--03.png"

//各ECカートのロゴ
import company from "../../images/p-logo--company.png"
import ecbeing from "../../images/p-logo--ecbeing.png"
import eccube from "../../images/p-logo--eccube.png"
import ecoringins from "../../images/p-logo--ecorigins.png"
import futureshop from "../../images/p-logo--futureshop.png"
import logica from "../../images/p-logo--logica.png"
import merucart from "../../images/p-logo--merucart.svg"
import shopify from "../../images/p-logo--shopify.svg"
import subscstore from "../../images/p-logo--subscstore.svg"
import vuestorefront from "../../images/p-logo--vuestorefront.png"

// メンバー画像
import yonemoto from "../../images/p-member--yonemoto.png"
import ouchi from "../../images/p-member--ouchi.png"

//背景の文字テキスト
import ecarchitect from "../../images/p-bgtext--ecarchitect.svg"
import member from "../../images/p-bgtext--member.svg"
import problem from "../../images/p-bgtext--problem.svg"
import reason from "../../images/p-bgtext--reason.svg"
import support from "../../images/p-bgtext--support.svg"
import totalsupport from "../../images/p-bgtext--totalsupport.svg"
import work from "../../images/p-bgtext--work.svg"

//TAMのロゴなど
import tamkun from "../../images/p-logo--tamkun.svg"

/* ==================================================
  コンポーネントの読み込み
================================================= */
import WorkSlider from "../../components/slick"
import { Form } from "../../components/form"
import scrollTo from "gatsby-plugin-smoothscroll"

const Lp01 = () => {
  // お問い合わせボタン表示位置
  const heroRef = useRef<HTMLHeadingElement>(null)
  const formRef = useRef<HTMLHeadingElement>(null)
  const footerRef = useRef<HTMLHeadingElement>(null)
  // const ref = React.createRef()

  const [showOnScroll, setshowOnScroll] = useState(false)

  useLayoutEffect(() => {
    if (
      heroRef.current != null &&
      formRef.current != null &&
      footerRef.current != null
    ) {
      const heroH = heroRef.current.scrollHeight
      const formH =
        document.body.scrollHeight -
        formRef.current.scrollHeight -
        footerRef.current.scrollHeight
      const onScroll = () => {
        const offset =
          window.pageYOffset ||
          (document.documentElement && document.documentElement.scrollTop) ||
          document.body.scrollTop
        if (offset >= heroH && offset < formH) {
          setshowOnScroll(true)
        } else {
          setshowOnScroll(false)
        }
      }

      document.addEventListener("scroll", onScroll)
      return () => document.removeEventListener("scroll", onScroll)
    }
  })

  return (
    <>
      <SEO title="ECサイトのカート選定から構築・プロモーションまで | TAM" />
      <Layout footerRef={footerRef} showScroll={showOnScroll}>
        <div className={Styles.main} id="top">
          <figure className={Styles.topBgHead}>
            <img src={totalsupport} />
          </figure>

          <section className={Styles.top} ref={heroRef}>
            <div className={Styles.topContainer}>
              <div className={Styles.topText}>
                <h1 className={Styles.topTitle}>
                  ECサイトを
                  <br />
                  トータルサポート
                </h1>
                <div className={Styles.topTitleSub}>
                  対応可能なECプラットフォーム業界最多
                  <span className={Styles.super}>※</span> <br />
                  課題抽出から運用までワンストップ
                </div>
                <a
                  onClick={() => scrollTo("#contact")}
                  className={Styles.btnContact}
                >
                  今すぐお問い合わせ
                </a>
              </div>
              <figure className={Styles.topImg}>
                <img
                  src={main}
                  alt="メインビジュアル"
                  width="542px"
                  height="600px"
                />
              </figure>
            </div>
            <div className={Styles.topTextUnder}>※弊社調べ</div>
            <figure className={Styles.topBgUnder}>
              <img src={ecarchitect} alt="ecarchitectのテキスト画像" />
            </figure>
          </section>

          {/* <section className={Styles.support}>
            {data ? <BlogPage data={data} /> : null}
          </section> */}

          <section className={Styles.support}>
            <figure className={Styles.supportBgHead}>
              <img
                data-src={support}
                className="lazyload"
                alt="totalsupportのテキスト画像"
              />
            </figure>
            <div className={Styles.supportWrap}>
              <h2 className={Styles.supportTitleMain}>サポート内容</h2>
              <div className={Styles.supportContainer}>
                <div className={Styles.supportItem}>
                  <figure className={Styles.supportImg}>
                    <img
                      data-src={support01}
                      className="lazyload"
                      alt="課題抽出・カート選定"
                    />
                  </figure>
                  <div className={Styles.supportTitle}>
                    課題抽出・カート選定
                  </div>
                  <div className={Styles.supportText}>
                    制作実績をもとに独自に課題を抽出し、課題を解決するカート選定を行います。TAMでは英語圏のスタッフやECプラットフォームの開発者との代理コミュニケーションも可能で、シームレスにサイト構築が行えます。
                  </div>
                </div>
                <div className={Styles.supportItem}>
                  <figure className={Styles.supportImg}>
                    <img
                      data-src={support02}
                      className="lazyload"
                      alt="課題抽出・カート選定"
                    />
                  </figure>
                  <div className={Styles.supportTitle}>戦略・プランニング</div>
                  <div className={Styles.supportText}>
                    ビジネスゴールに向け、TAM独自のフレームワークをもとに「開発」から「コンバージョン」までカート運用をサポートします。
                  </div>
                </div>
                <div className={Styles.supportItem}>
                  <figure className={Styles.supportImg}>
                    <img
                      data-src={support03}
                      className="lazyload"
                      alt="サイト構築"
                    />
                  </figure>
                  <div className={Styles.supportTitle}>サイト構築</div>
                  <div className={Styles.supportText}>
                    ECの構築・運用を行う中で、課題と改善を重ねてECに特化した、UIUX知識・フロントエンド技術を強化し、ナレッジを磨いてきました。TAMではより洗練されたサイト構築が可能です。
                  </div>
                </div>
                <div className={Styles.supportItem}>
                  <figure className={Styles.supportImg}>
                    <img
                      data-src={support04}
                      className="lazyload"
                      alt="サイト構築"
                    />
                  </figure>
                  <div className={Styles.supportTitle}>
                    運用・コンテンツ制作
                  </div>
                  <div className={Styles.supportText}>
                    ECサイト運用でPDCAサイクルの実行を行い、サイト改善に継続的に取り組んでいきます。
                  </div>
                </div>
              </div>
              <div className={Styles.supportUnderText}>
                ※弊社でお受けしているのはデジタルマーケティングおよび制作運⽤のみです。
                <br />
                受発注業務や物流・コールセンター業務は対応しておりません。
              </div>
            </div>
          </section>

          <section className={Styles.problem}>
            <figure className={Styles.problemBgHead}>
              <img
                data-src={problem}
                className="lazyload"
                alt="problemのテキスト画像"
              />
            </figure>
            <div className={Styles.problemWrap}>
              <figure className={Styles.problemLogo}>
                <img
                  className={Styles.problemImg}
                  src={tamkun}
                  alt="タムくん"
                />
              </figure>
              <h2 className={Styles.problemTitle}>
                こんなお悩み
                <br className={Styles.displayNone} />
                ありませんか？
              </h2>
            </div>
            <div className={Styles.problemContainer}>
              <ul className={Styles.problemList}>
                <li className={Styles.problemItem}>
                  今のカートが事業規模に合わなくなってきた。
                </li>
                <li className={Styles.problemItem}>
                  カートのバージョンアップにかかる費⽤を抑えたい。
                </li>
                <li className={Styles.problemItem}>
                  どのカートがいいか分からない。
                </li>
                <li className={Styles.problemItem}>
                  できれば構築後の制作も任せたい。
                </li>

                <li className={Styles.problemItem}>
                  どうすればECで売り上げが伸ばせるか相談したい。
                </li>
              </ul>
            </div>
          </section>
          <section className={Styles.select}>
            <h2 className={Styles.selectMainTitle}>
              <span className={Styles.txtWrapColorMain}>TAM</span>が選ばれるワケ
            </h2>
            <div className={Styles.selectTitle}>
              <h3 className={Styles.selectTitleLeft}>
                <span className={Styles.selectfont38}>TAM</span>に
                <br className={Styles.displayNone} />
                依頼した場合
              </h3>
              <h3 className={Styles.selectTitleRight}>
                <span className={Styles.selectfont38}>自社</span>で
                <br className={Styles.displayNone} />
                運用する場合
              </h3>
            </div>
            <div className={Styles.selectContainer}>
              <div className={Styles.selectItem}>
                <h4 className={Styles.selectItemTitle}>カート選定</h4>
                <div className={Styles.selectItemWrap}>
                  <div className={Styles.selectItemLeft}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select01}
                        className="lazyload"
                        alt="カート選定"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      御社の既存のカート機能を把握した上で、弊社から
                      <span className={Styles.txtWrapColorMain}>
                        おすすめのカート
                      </span>
                      を提案させていただきます。
                    </div>
                  </div>
                  <div className={Styles.selectItemRight}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select02}
                        className="lazyload"
                        alt="カート選定"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      ECプラットフォームが多く、メリット、デメリットの把握を全てすることが困難で、目的に合わないカートを選ぶ可能性があります。
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.selectItem}>
                <h4 className={Styles.selectItemTitle}>サイト構築</h4>
                <div className={Styles.selectItemWrap}>
                  <div className={Styles.selectItemLeft}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select03}
                        className="lazyload"
                        alt="サイト構築"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      英語圏のスタッフやECプラットフォーム開発者との
                      <span className={Styles.txtWrapColorMain}>
                        代理コミュニケーション
                      </span>
                      が可能です。
                    </div>
                  </div>
                  <div className={Styles.selectItemRight}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select04}
                        className="lazyload"
                        alt="サイト構築"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      サイト構築のディレクションには多くの知識が必要で、どのようにサイトを構築していくか考察していく必要があります。
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.selectItem}>
                <h4 className={Styles.selectItemTitle}>制作・運用</h4>
                <div className={Styles.selectItemWrap}>
                  <div className={Styles.selectItemLeft}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select05}
                        className="lazyload"
                        alt="制作・運用"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      <span className={Styles.txtWrapColorMain}>
                        サイト構築後の制作や運用
                      </span>
                      もTAMのEC専門チームにお任せください。制作実績をもとに独自の課題を抽出しご提案します。
                    </div>
                  </div>
                  <div className={Styles.selectItemRight}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select06}
                        className="lazyload"
                        alt="制作・運用"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      商品のデザインやwebマーケティング戦略の考案など多くのリソースが必要となり、スムーズな制作・運用が難しくなります。
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.selectItem}>
                <h4 className={Styles.selectItemTitle}>結果</h4>
                <div className={Styles.selectItemWrap}>
                  <div className={Styles.selectItemLeft}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select07}
                        className="lazyload"
                        alt="結果"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      目的から逆算し、カート選定から運用までワンストップで実施することで
                      <span className={Styles.txtWrapColorMain}>
                        問合せや商品購入に繋がります。
                      </span>
                    </div>
                  </div>
                  <div className={Styles.selectItemRight}>
                    <figure className={Styles.selectItemImg}>
                      <img
                        data-src={select08}
                        className="lazyload"
                        alt="制作・運用"
                      />
                    </figure>
                    <div className={Styles.selectItemText}>
                      ECサイトリニューアルにかかる負荷が膨大で本来注力すべきビジネス部分に注力できなくなります。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={Styles.reason}>
            <figure className={Styles.reasonBgHead}>
              <img
                data-src={reason}
                className="lazyload"
                alt="reasonのテキスト画像"
              />
            </figure>
            <div className={Styles.reasonWrap}>
              <h2 className={Styles.reasonMainTitle}>
                成果が上がる
                <br className={Styles.displayNone} />
                <span className={Styles.reasonFont60}>3</span>つの根拠
              </h2>
              <div className={Styles.reasonContainer}>
                <div className={Styles.reasonItem}>
                  <figure className={Styles.reasonItemImg}>
                    <img
                      data-src={reason01}
                      className="lazyload"
                      alt="業界最多※のECプラットフォーム取扱数"
                    />
                    <span className={Styles.reasonNumber}>01</span>
                  </figure>
                  <h3 className={Styles.reasonTitle}>
                    業界最多<span className={Styles.super}>※</span>の
                    <br />
                    ECプラットフォーム取扱数
                  </h3>
                  <div className={Styles.reasonText}>
                    10種類ものECプラットフォームに対応することができ、業界最⼤⼿の企業様も含む多くの企業様のwebマーケティング戦略に貢献した経験から、事業規模に合わせたカートを選定することが可能です。
                  </div>
                  <div className={Styles.reasonTextUnder}>※弊社調べ</div>
                </div>
                <div className={Styles.reasonItem}>
                  <figure className={Styles.reasonItemImg}>
                    <img
                      data-src={reason02}
                      className="lazyload"
                      alt="ワンストップ提供EC"
                    />
                    <span className={Styles.reasonNumber}>02</span>
                  </figure>

                  <h3 className={Styles.reasonTitle}>ワンストップ提供EC</h3>
                  <div className={Styles.reasonText}>
                    ECカート選定、ECサイト構築だけでなくこれまでのサイト実績の課題抽出〜戦略の⽴案、プランニング〜PDCA運⽤までを社内で⼀元管理できることから⽣まれる成果をご提供できます。
                  </div>
                </div>
                <div className={Styles.reasonItem}>
                  <figure className={Styles.reasonItemImg}>
                    <img
                      data-src={reason03}
                      className="lazyload"
                      alt="クオリティ"
                    />
                    <span className={Styles.reasonNumber}>03</span>
                  </figure>
                  <h3 className={Styles.reasonTitle}>クオリティ</h3>
                  <div className={Styles.reasonText}>
                    既存のEC機能で運⽤している「受注フロー」「定期購⼊」「セキュリティ」「顧客データ」など細部にわたり把握し、且つ豊富な経験をもとに⾒ためだけではなく、成果を上げる=事業規模を⼤きくすることを⽬的としたECサイトを構築します。
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={Styles.cart}>
            <h2 className={Styles.cartTitle}>
              国内外10種類の
              <br />
              カート対応
            </h2>
            <div className={Styles.cartContainer}>
              <ul className={Styles.cartList}>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={ecbeing}
                    className="lazyload"
                    alt="ecbeingのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={logica}
                    className="lazyload"
                    alt="logicaのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    src={eccube}
                    className={Styles.logoEccube}
                    alt="eccubeのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={company}
                    className="lazyload"
                    alt="companyのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={shopify}
                    className="lazyload"
                    alt="shopifyのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={futureshop}
                    className="lazyload"
                    alt="futureshopのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <a
                    href="https://www.vuestorefront.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      data-src={vuestorefront}
                      className="lazyload"
                      alt="vuestorefrontのロゴ"
                    />
                  </a>
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={ecoringins}
                    className="lazyload"
                    alt="ecoriginsのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={merucart}
                    className="lazyload"
                    alt="merucartのロゴ"
                  />
                </li>
                <li className={Styles.cartListItem}>
                  <img
                    data-src={subscstore}
                    className="lazyload"
                    alt="subscstoreのロゴ"
                  />
                </li>
              </ul>
            </div>
          </section>
          <section className={Styles.member}>
            <div className={Styles.memberContainer}>
              <div className={Styles.memberItem}>
                <figure className={Styles.memberImg}>
                  <img data-src={ouchi} className="lazyload" alt="大内さん" />
                </figure>
                <div className={Styles.memberWrap}>
                  <div className={Styles.memberTitle}>
                    ECアーキテクト/リーダー
                  </div>
                  <div className={Styles.memberBody}>
                    <div className={Styles.memberText}>
                      制作側と受託側両⾯から⼤規模ECサイトに携わった経験を活かし、
                      <br />
                      クライアントの課題解決・運⽤効率化を主軸に置いた提案をしています。
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.memberItem}>
                <figure className={Styles.memberImg}>
                  <img
                    data-src={yonemoto}
                    className="lazyload"
                    alt="米本さん"
                  />
                </figure>
                <div className={Styles.memberWrap}>
                  <div className={Styles.memberTitle}>
                    ECアーキテクト/
                    <br className={Styles.displayNone} />
                    コーダー＆デザイナー
                  </div>
                  <div className={Styles.memberBody}>
                    <div className={Styles.memberText}>
                      機能性を追求した構築だけではなく、
                      <br className={Styles.display} />
                      ユーザーへのインパクト、競合サイトとの差別化など
                      <br />
                      最新技術を取り入れて効果アップを実現させています。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2 className={Styles.memberTitleVertical}>
              私たちが
              <br />
              サポートします
            </h2>
            <figure className={Styles.memberBgUnder}>
              <img
                data-src={member}
                className="lazyload"
                alt="memberのテキスト画像"
              />
            </figure>
          </section>
          <section className={Styles.work} id="work">
            <h2 className={Styles.workTitleMain}>
              ECサイト構築
              <br className={Styles.displayNone} />
              運⽤実績
            </h2>
            <p className={Styles.workText}>
              大手通販企業のサイトリニューアルから
              <br className={Styles.displayNone} />
              プライベートブランドの
              <br className={Styles.displayNone} />
              ECサイト立ち上げや更新運用まで。
            </p>
            <div>
              <WorkSlider />
            </div>
            <figure className={Styles.workBgUnder}>
              <img
                data-src={work}
                className="lazyload"
                alt="workのテキスト画像"
              />
            </figure>
          </section>
          <Form ref={formRef} />
        </div>
      </Layout>
    </>
  )
}

export default Lp01